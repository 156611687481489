import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useLeadDashboard } from 'contexts/leadDashboardContext';
import { LeadDashboardType, LeadSegmentEnum } from 'types';

import LeadStatusFilterTemplate from 'components/Dashboard/LeadDashboard/FiltersTemplate/LeadStatus';
import LeadPOMonthFilterTemplate from 'components/Dashboard/LeadDashboard/FiltersTemplate/POMonth';
import LeadWonAndLossFilterTemplate from 'components/Dashboard/LeadDashboard/FiltersTemplate/WonAndLoss';
import LeadKpiCard from 'components/Dashboard/LeadDashboard/KpiCard';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import theme from './theme.module.scss';

const GET_DASBOARD_QUERY = gql`
  query GetDashboardData($segment: [LeadSegmentEnum!]) {
    getDashboardData(segment: $segment) {
      rfpSubmitted {
        leadCount
        totalApproxValue
      }
      rfpInProgress {
        leadCount
        totalApproxValue
      }
      pqSubmitted {
        leadCount
        totalApproxValue
      }
      pqInProgress {
        leadCount
        totalApproxValue
      }
      currentMonthLeads {
        month
        leadCount
        totalApproxValue
      }
      nextMonthLeads {
        month
        leadCount
        totalApproxValue
      }
      nextNextMonthLeads {
        month
        leadCount
        totalApproxValue
      }
      otherMonthLeads {
        month
        leadCount
        totalApproxValue
      }
      wonLeads {
        leadCount
        totalApproxValue
      }
      lostLeads {
        leadCount
        totalApproxValue
      }
      regretLeads {
        leadCount
        totalApproxValue
      }
    }
  }
`;

type GetDashboardDataQueryResponse = {
  getDashboardData: LeadDashboardType;
};

type GetDashboardDataQueryVariables = {
  segment?: LeadSegmentEnum[];
};

const LeadDashboard = () => {
  const { filters } = useLeadDashboard();
  const {
    data: dashboard,
    networkStatus,
    refetch: refetchDashboard,
  } = useQuery<GetDashboardDataQueryResponse, GetDashboardDataQueryVariables>(GET_DASBOARD_QUERY, {
    variables: {
      segment: filters.segment,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingDashboardData =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!dashboard;

  const handleKPIChange = (newSegments: LeadSegmentEnum[]) => {
    refetchDashboard({
      segment: newSegments,
    });
  };

  return (
    <Navbar title="Dashboard">
      <Grid container direction="column" mb={5} justifyContent="center">
        <Grid className={theme.filtersContainer}>
          <Grid className={theme.filterItem}>
            <LeadKpiCard
              onClick={handleKPIChange}
              iconSrc={'/segment-icons/semiconductor.svg'}
              type={LeadSegmentEnum['SEMI CONDUCTOR']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadKpiCard
              onClick={handleKPIChange}
              iconSrc={'/segment-icons/dc.svg'}
              type={LeadSegmentEnum['DATA CENTER (DC)']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadKpiCard
              onClick={handleKPIChange}
              iconSrc={'/segment-icons/pharma.svg'}
              type={LeadSegmentEnum['RESEARCH LAB - LIFE SCIENCES']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadKpiCard
              onClick={handleKPIChange}
              iconSrc={'/segment-icons/os.svg'}
              type={LeadSegmentEnum['OFFICE SPACE (OS)']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadKpiCard
              onClick={handleKPIChange}
              iconSrc={'/segment-icons/edu-institute.svg'}
              type={LeadSegmentEnum['EDUCATION INSTITUTE']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadKpiCard
              onClick={handleKPIChange}
              iconSrc={'/segment-icons/hospital.svg'}
              type={LeadSegmentEnum.HOSPITAL}
            />
          </Grid>
        </Grid>
        {loadingDashboardData ? (
          <Grid item xs={12} mt={5}>
            <LoadingIndicator />
          </Grid>
        ) : (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            rowSpacing={2}
            columnSpacing={2}
            width="100%"
            mt={2}
          >
            <LeadStatusFilterTemplate dashboard={dashboard.getDashboardData} />
            <LeadPOMonthFilterTemplate dashboard={dashboard.getDashboardData} />
            <LeadWonAndLossFilterTemplate dashboard={dashboard.getDashboardData} />
          </Grid>
        )}
      </Grid>
    </Navbar>
  );
};

export default LeadDashboard;
