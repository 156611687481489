import { Typography } from '@mui/material';
import classNames from 'classnames';
import { useLeadDashboard } from 'contexts/leadDashboardContext';
import React from 'react';
import { LeadSegmentEnum } from 'types';

import theme from './theme.module.scss';

const LeadKpiCard: React.FC<{
  iconSrc: string;
  type: LeadSegmentEnum;
  onClick: (newSegments: LeadSegmentEnum[]) => void;
}> = ({ iconSrc, type, onClick }) => {
  const { filters, setFilters } = useLeadDashboard();

  const selected = filters.segment.find(val => val === type);

  const toggleSelection = () => {
    const newSegments = selected
      ? filters.segment.filter(val => val !== type)
      : [...filters.segment, type];

    setFilters(prev => ({
      ...prev,
      segment: newSegments,
    }));

    onClick(newSegments);
  };

  const getLabel = () => {
    switch (type) {
      case LeadSegmentEnum.HOSPITAL:
        return 'Hospital';
      case LeadSegmentEnum['DATA CENTER (DC)']:
        return 'Data Center';
      case LeadSegmentEnum['EDUCATION INSTITUTE']:
        return 'Education';
      case LeadSegmentEnum['OFFICE SPACE (OS)']:
        return 'Office Space';
      case LeadSegmentEnum['RESEARCH LAB - LIFE SCIENCES']:
        return 'Pharma';
      case LeadSegmentEnum['SEMI CONDUCTOR']:
        return 'Semiconductor';
      default:
        return 'Others';
    }
  };

  return (
    <div
      className={classNames(theme.kpiCard, selected ? theme.kpiCardSelected : '')}
      onClick={toggleSelection}
    >
      <img src={iconSrc} alt="lead-kpi" width="35px" height="25px" />
      <Typography
        variant="inherit"
        className={theme.title}
        color={theme => theme.palette.secondary.main}
      >
        {getLabel()}
      </Typography>
    </div>
  );
};

export default LeadKpiCard;
