import React, { useContext, useState } from 'react';
import { LeadSegmentEnum, LeadStatusEnum, MonthTypeEnum } from 'types';

export type LeadDashboardFiltersType = {
  segment: LeadSegmentEnum[];
  monthType?: MonthTypeEnum;
  leadStatus?: LeadStatusEnum[];
  searchTerm?: string;
};

type LeadDashboardType = {
  filters: LeadDashboardFiltersType;
  setFilters: React.Dispatch<React.SetStateAction<LeadDashboardFiltersType>>;
};

const LeadDashboardContext = React.createContext<LeadDashboardType>({
  filters: {
    segment: [],
  },
  setFilters: () => {},
});

export const LeadDashboardContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [filters, setFilters] = useState<LeadDashboardFiltersType>({
    segment: [],
  });
  return (
    <LeadDashboardContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      {children}
    </LeadDashboardContext.Provider>
  );
};

export const useLeadDashboard = () => {
  const context = useContext(LeadDashboardContext);

  if (context === null) {
    throw new Error('useLeadDashboard must be used within a LeadDashboardProvider');
  }
  return context;
};
