import { Chip, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { useLeadDashboard } from 'contexts/leadDashboardContext';
import { useNavigate } from 'react-router-dom';
import { LeadDashboardType, LeadStatusEnum } from 'types';

import LeadFilterButton from '../FilterButton';
import theme from './theme.module.scss';

const LeadWonAndLossFilterTemplate: React.FC<{ dashboard: LeadDashboardType }> = ({
  dashboard,
}) => {
  const { setFilters } = useLeadDashboard();
  const navigate = useNavigate();

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const handleClick = (status: LeadStatusEnum[]) => {
    setFilters(prev => ({
      segment: prev.segment,
      leadStatus: status,
    }));
    navigate('dashboard-leads');
  };

  return (
    <div className={theme.wonAndLossContainer}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        rowGap={2}
        columnSpacing={2}
        mx="auto"
      >
        <Grid item xs={12} mt={2}>
          <Divider variant="middle">
            <Typography variant="button" fontWeight={700}>
              <Chip label="WON / LOST / REGRET" size="small" variant="outlined" color="secondary" />
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={isMobileScreen ? 6 : 4}>
          <LeadFilterButton
            amount={dashboard.wonLeads.totalApproxValue}
            leadsCount={dashboard.wonLeads.leadCount}
            label="Won"
            onClick={() => handleClick([LeadStatusEnum.WON])}
          />
        </Grid>
        <Grid item xs={isMobileScreen ? 6 : 4}>
          <LeadFilterButton
            amount={dashboard.lostLeads.totalApproxValue}
            leadsCount={dashboard.lostLeads.leadCount}
            label="Lost"
            onClick={() => handleClick([LeadStatusEnum.LOST])}
          />
        </Grid>
        <Grid item xs={isMobileScreen ? 7 : 4}>
          <LeadFilterButton
            amount={dashboard.regretLeads.totalApproxValue}
            leadsCount={dashboard.regretLeads.leadCount}
            label="REGRET"
            onClick={() => handleClick([LeadStatusEnum.REGRET])}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LeadWonAndLossFilterTemplate;
