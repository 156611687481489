import { Box, Typography } from '@mui/material';
import React from 'react';

import { fixToTwoLocalPrice } from 'utils/formatHelper';

import theme from './theme.module.scss';

const LeadFilterButton: React.FC<{
  label: string;
  leadsCount: number;
  amount: number;
  onClick: () => void;
}> = ({ label, amount, leadsCount, onClick }) => {
  return (
    <div className={theme.card} onClick={onClick}>
      <Box className={theme.cardLeadsContainer}>
        <Typography variant="caption" fontWeight={600} lineHeight={1.5}>
          {fixToTwoLocalPrice(leadsCount)}
        </Typography>
      </Box>
      <div className={theme.labelandAmount}>
        <Typography className={theme.cardTitle}>{label}</Typography>
        <Typography variant="h6" className={theme.cardAmount}>
          ₹{amount} Cr
        </Typography>
      </div>
    </div>
  );
};

export default LeadFilterButton;

/* <div className={theme.card} onClick={onClick}>
      <Box className={theme.cardLeadsContainer}>
        <Typography variant="caption" fontWeight={600}>
          {fixToTwoLocalPrice(leadsCount)}
        </Typography>
      </Box>
      <Box className={theme.cardTitleContainer}>
        <Typography fontWeight={700} color={'grey'} sx={{ fontSize: '10px' }}>
          {label}
        </Typography>
      </Box>
      <div className={theme.cardAmountContainer}>
        <Typography variant="h5" fontWeight={500} color={theme => theme.palette.secondary.main}>
          ₹{amount} Cr
        </Typography>
      </div>
    </div> */
