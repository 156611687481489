import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import { useLeadDashboard } from 'contexts/leadDashboardContext';
import { useNavigate } from 'react-router-dom';
import { LeadDashboardType, MonthTypeEnum } from 'types';

import LeadFilterButton from '../FilterButton';

const LeadPOMonthFilterTemplate: React.FC<{ dashboard: LeadDashboardType }> = ({ dashboard }) => {
  const { setFilters } = useLeadDashboard();
  const navigate = useNavigate();

  const handleClick = (type: MonthTypeEnum) => {
    setFilters(prev => ({
      segment: prev.segment,
      monthType: type,
    }));
    navigate('dashboard-leads');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        rowGap={1.5}
        columnSpacing={2}
        width="100%"
        maxWidth={'700px'}
        mx="auto"
      >
        <Grid item xs={12} mt={2}>
          <Divider variant="middle">
            <Typography variant="button" fontWeight={700}>
              <Chip label="WORK IN PROGRESS" size="small" variant="outlined" color="secondary" />
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={6} md={3}>
          <LeadFilterButton
            amount={dashboard.currentMonthLeads.totalApproxValue}
            leadsCount={dashboard.currentMonthLeads.leadCount}
            label={dashboard.currentMonthLeads.month}
            onClick={() => handleClick(MonthTypeEnum.CURRENT)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <LeadFilterButton
            amount={dashboard.nextMonthLeads.totalApproxValue}
            leadsCount={dashboard.nextMonthLeads.leadCount}
            label={dashboard.nextMonthLeads.month}
            onClick={() => handleClick(MonthTypeEnum.NEXT)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <LeadFilterButton
            amount={dashboard.nextNextMonthLeads.totalApproxValue}
            leadsCount={dashboard.nextNextMonthLeads.leadCount}
            label={dashboard.nextNextMonthLeads.month}
            onClick={() => handleClick(MonthTypeEnum.NEXT_NEXT)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <LeadFilterButton
            amount={dashboard.otherMonthLeads.totalApproxValue}
            leadsCount={dashboard.otherMonthLeads.leadCount}
            label={dashboard.otherMonthLeads.month}
            onClick={() => handleClick(MonthTypeEnum.OTHERS)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadPOMonthFilterTemplate;
