import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import { useLeadDashboard } from 'contexts/leadDashboardContext';
import { useNavigate } from 'react-router-dom';
import { LeadDashboardType, LeadStatusEnum } from 'types';

import LeadFilterButton from '../FilterButton';

const LeadStatusFilterTemplate: React.FC<{ dashboard: LeadDashboardType }> = ({ dashboard }) => {
  const { setFilters } = useLeadDashboard();
  const navigate = useNavigate();

  const handleClick = (type: LeadStatusEnum) => {
    setFilters(prev => ({
      segment: prev.segment,
      leadStatus: [type],
    }));
    navigate('dashboard-leads');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        rowSpacing={1.5}
        columnSpacing={2}
        width="100%"
        maxWidth="700px"
        mx="auto"
      >
        <Grid item xs={12} md={6}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Divider variant="middle">
                <Typography variant="button" fontWeight={700}>
                  <Chip label="RFP" size="small" variant="outlined" color="secondary" />
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={6}>
              <LeadFilterButton
                amount={dashboard.rfpInProgress.totalApproxValue}
                leadsCount={dashboard.rfpInProgress.leadCount}
                label="RFP Received"
                onClick={() => handleClick(LeadStatusEnum['RFP RECEIVED'])}
              />
            </Grid>
            <Grid item xs={6}>
              <LeadFilterButton
                amount={dashboard.rfpSubmitted.totalApproxValue}
                leadsCount={dashboard.rfpSubmitted.leadCount}
                label="RFP Submitted"
                onClick={() => handleClick(LeadStatusEnum['RFP SUBMITTED'])}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Divider variant="middle">
                <Typography variant="button" fontWeight={700}>
                  <Chip label="PQ" size="small" variant="outlined" color="secondary" />
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={6}>
              <LeadFilterButton
                amount={dashboard.pqInProgress.totalApproxValue}
                leadsCount={dashboard.pqInProgress.leadCount}
                label="PQ Received"
                onClick={() => handleClick(LeadStatusEnum['PQ RECEIVED'])}
              />
            </Grid>
            <Grid item xs={6}>
              <LeadFilterButton
                amount={dashboard.pqSubmitted.totalApproxValue}
                leadsCount={dashboard.pqSubmitted.leadCount}
                label="PQ Submitted"
                onClick={() => handleClick(LeadStatusEnum['PQ SUBMITTED'])}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadStatusFilterTemplate;
